import api from "@/services/api"
import store from "@/store"
import axios from "axios"

import { HttpError } from "@/services/HttpError";
import logger from "@/utilities/logger";

class CrudClient {
	constructor(urlPart) {
		this.urlPart = urlPart;
	}

	async _fetch(method, url, expectReturnData = true, dto) {
		const options = {
			method: method,
			headers: new Headers({
				...store.getters.getAuthHeader
			}),
		};

		if (dto !== undefined) {
			if (dto instanceof FormData) {
				options.body = dto;
			} else {
				options.body = JSON.stringify(dto);
				options.headers.append("Content-Type", "application/json");
			}
		}

		const response = await fetch(url, options);

		if (response.ok) {
			if (expectReturnData) {
				const contentType = response.headers.get("Content-Type");
				if (contentType && contentType.includes("application/json")) {
					return await response.json();
				} else if (contentType && (contentType.includes("text/plain") || contentType.includes("text/csv"))) {
					return await response.text();
				} else {
					logger.$log.error("Unexpected content type")
				}
			} else {}
		} else {
			let body = null;
			if (response.body instanceof ReadableStream) { body = await response.json(); }

			throw new HttpError({
				method: method,
				requestUrl: url,
				postedData: dto,
				status: response.status,
				statusText: response.statusText,
				body
			});
		}
	}

	_urlBuilder(id, urlPostFix, addApplicationId = true) {
		let url = `${api.url}${this.urlPart}`;

		if (addApplicationId === true) { url += `/${store.getters.selectedApplicationId}`; }

		if (id || id === 0) { url += `/${id}`; }

		if (urlPostFix) { url += "/" + urlPostFix; }

		return url;
	}

	async GetMetadata(addApplicationId = true) {
		return this.GetCustom("Metadata", addApplicationId);
	}

	async GetPaged({
		limit,
		skip,
		orderBy,
		filter,
		fields,
		urlPostFix,
		addApplicationId = true
	}) {
		let url = this._urlBuilder(null, urlPostFix, addApplicationId);

		const parameters = [];

		parameters.push(`limit=${!limit ? 0 : limit}`);
		parameters.push(`skip=${skip}`);

		if (orderBy) {
			parameters.push(`orderBy=${orderBy}`);
		}
		if (filter) {
			parameters.push(`filter=${filter}`);
		}
		if (fields) {
			parameters.push(`fields=${fields}`);
		}

		// Notes: a last & will cause errors for web api, use join
		url += "/?" + parameters.join("&");

		return await this._fetch("GET", url);
	}

	async Get(id, urlPostFix) {
		return this.GetSelectedFields(id, null, urlPostFix);
	}

	async GetSelectedFields(id, fields, urlPostFix, addApplicationId = true) {
		let url = this._urlBuilder(id, urlPostFix, addApplicationId);

		if (fields) { url = url + "/?fields=" + fields; }

		return await this._fetch("GET", url);
	}

	async Count({
		filter,
		addApplicationId = false
	}) {
		let url = this._urlBuilder(null, "Count", addApplicationId);

		const parameters = [];
		if (filter) {
			parameters.push(`filter=${filter}`);
		}

		url += "?" + parameters.join("&");

		return await this._fetch("GET", url);
	}

	async GetCustom(urlPostFix, addApplicationId = true) {
		const url = this._urlBuilder(null, urlPostFix, addApplicationId);

		return await this._fetch("GET", url);
	}

	async Post(id, dto, urlPostFix, expectReturnData = false) {
		return await this.PostCustom(id, dto, urlPostFix, expectReturnData, true);
	}

	async PostCustom(id, dto, urlPostFix, expectReturnData = false, addApplicationId = true) {
		const url = this._urlBuilder(id, urlPostFix, addApplicationId);

		return await this._fetch("POST", url, expectReturnData, dto);
	}

	CancelTokenSourceForPostWithProgress() {
		const CancelToken = axios.CancelToken;
		// create the source
		return CancelToken.source();

		// source.cancel('User navigated to different page');
		// source.token
	}

	//
	async PostCustomWithProgress(id, dto, {
		urlPostFix = null,
		expectReturnData = false,
		addApplicationId = true,
		onUploadProgress = null,
		onDownloadProgress = null,
		cancelToken = null

	} = {}) {
		const url = this._urlBuilder(id, urlPostFix, addApplicationId);

		// const CancelToken = axios.CancelToken;
		// // create the source
		// let source = CancelToken.source();
		//	source.cancel('User navigated to different page');

		const config = {
			onUploadProgress,
			onDownloadProgress,
			headers: {
				...store.getters.getAuthHeader
			},
			cancelToken: cancelToken

		};

		try {
			const res = await axios.post(url, dto, config);
			if (expectReturnData) { return await res.json(); }
		} catch (error) {
			if (axios.isCancel(error)) {
				// console.log("Axios isCancel");
				// console.log(error.message);
				return;
			}

			throw new HttpError({
				method: "POST",
				requestUrl: url,
				postedData: dto,
				status: error.response.status,
				statusText: error.response.statusText,
				body: error.response.data
			});
		}
	}

	async Delete(id, urlPostFix, expectReturnData = false, addApplicationId = true) {
		const url = this._urlBuilder(id, urlPostFix, addApplicationId);

		return await this._fetch("DELETE", url, expectReturnData);
	}

	async Add(dto) {
		const url = this._urlBuilder(null, null);
		return await this._fetch("POST", url, true, dto);
	}

	async Patch(id, dto, expectReturnData = true, addApplicationId = true) {
		const url = this._urlBuilder(id, null, addApplicationId);

		return await this._fetch("PATCH", url, expectReturnData, dto);
	}

	// IItemWithImageController
	async UpdateImage(id, imgFileName, imgFile, urlPostFix = "image") {
		const formData = new FormData();
		formData.append("File", imgFile);

		const url = this._urlBuilder(id, urlPostFix);

		return await this._fetch("POST", url, true, formData);
	}

	async DeleteImage(id) {
		return await this.Delete(id, "image", true)
	}

	// Taggable
	async GetTags(id) {
		if (!id) { throw new Error("Missing parameter: id"); }
		return this.Get(id, "tags");
	}

	async AddTag(id, tagName) {
		if (!id) { throw new Error("Missing parameter: id"); }
		const url = this._urlBuilder(id, "tags");
		return await this._fetch("POST", url, false, tagName);
	}

	async RemoveTag(id, tagId) {
		if (!id) { throw new Error("Missing parameter: id"); }
		return await this.Delete(id, `tags/${tagId}`)
	}

	async GetAutocomplete(id, prefix) {
		if (!id) { throw new Error("Missing parameter: id"); }
		return await this.Get(id, `tags/autocomplete/${prefix}`)
	}

	// Reorder
	async Reorder(itemIds, sectionName) {
		let postUrl = this._urlBuilder(null);

		postUrl += (sectionName === undefined) ? "/Reorder" : `/${sectionName}/Reorder`;

		return await this._fetch("POST", postUrl, false, itemIds);
	}
}

export default CrudClient;
